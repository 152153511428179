<template>
	<v-card dense>
		<v-col>
		<!-- <s-toolbar
			dark
			color="#BDBDBD"
			close
			@close="closeDialogAdmin()"
		></s-toolbar> -->

		<v-row>
			<v-col>
				<s-crud
					:config="config"
					:filter="filter"
					close
					title="Pallets en cámara"
					@close="closeDialogAdmin()"
				>
					<template v-slot:filter>
						<v-container>
							<v-row>
								<v-col>
									<s-select-customer 
										clearable 
										label="Cliente" 
										v-model="filter.UwoCustomerID"
									></s-select-customer>
								</v-col>
							</v-row>
						</v-container>
					</template>
					<template v-slot:PltID="{ row }">
						 {{row.PltID + "-" + row.PltCorrelative}}
					</template>
				</s-crud>
			</v-col>
		</v-row>
		</v-col>
	</v-card>
</template>

<script>
	import sSelectCustomer from "@/components/Sale/SselectClientSap.vue";

	import _sPrfCoolingChamber from "@/services/FreshProduction/PrfCoolingChamberService";

	export default {
		components: {
			sSelectCustomer,
		},

		props: {
			chamberSelected: null
		},

		data() {
			return {
				config: {
					model: {
						PltID: "ID"
					},
					service: _sPrfCoolingChamber,
					headers: [
						{text:"Pallet", value: "PltID"},
						{text:"Horas Dentro", value: "TimeInside"},
						{text:"Cliente", value: "CardName"},
						{text:"Calibre", value: "TypeCaliberName"},
						{text:"Presentación", value: "PresentationName"},
						{text:"Cultivo", value: "TypeCultiveName"},
						{text:"Tipo Cultivo", value: "TypeCropName"},
						{text:"Variedad", value: "VarietyName"},
					]
				},
				filter:{
					ChaID: this.chamberSelected.ChaID
				}
			}
		},

		methods: {
			closeDialogAdmin() {
				this.$emit("closeDialogAdmin")
			}
		},

		created () {
			console.log('chamberSelected ', this.chamberSelected);;
		},
	}
</script>

<style scoped>

</style>