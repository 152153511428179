<template>
	<v-card dense>
		<s-toolbar
			dark
			color="#BDBDBD"
			:label="itemsPallet.ChaDescription"
			close
			@close="closeDetail"
		></s-toolbar>
		<!-- <v-toolbar dark color="primary">
				<v-btn icon dark @click="closeDetail">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>{{chamber.ChaDescription}}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar> -->
		<!-- <v-container> -->
		<v-row>
			<div class="col-md-12 col-sm-12">
				<v-toolbar dense>
					<v-toolbar-title>Scanner QR</v-toolbar-title>
				</v-toolbar>
				<v-card dense>
					<v-row justify="center" style="margin: auto">
						<v-col align="center" cols="12" lg="8" md="8" v-if="item.CocDateInitialProcess == null">
							<s-scanner-qr
								:config="configScann"
								autofocus
								return-object
								@onValue="onValue($event)"
								:readonly="false"
								@keyupEnter="goToChamber"
							></s-scanner-qr>
						</v-col>
					</v-row>

					<!-- <v-row style="margin: auto;">
							<v-col
								cols="6"
								lg="1"
								md="4"
								sm="4"
								v-for="item in palletsWaiting"
								:key="item.PltID"
							>
								<v-card height="130" max-width="150" outlined>
									<v-card-text class="text-center">
										{{ "P - " + item.PltID }}
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											rounded
											color="primary"
											small
											@click="goToChamber(item)"
										>
											<v-icon>
												fas fa-upload
											</v-icon>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row> -->
				</v-card>
			</div>
		</v-row>
		<v-row style="margin: auto" align="center" justify="center">
			<v-col cols="12" lg="6" justify="center">
				<v-card class="mx-auto" outlined>
					<v-list-item three-line>
						<v-list-item-content>
							<v-list-item-title class="text-h5 mb-1">
								<b>TEMPERATURA AMBIENTE (°C)</b>
							</v-list-item-title>
						</v-list-item-content>

						<v-list-item-avatar
							tile
							size="100"
							color="black"
							style="box-shadow: 1px 0px 4px 5px"
							><b style="color: red; font-size: 26px"
								><h1>
									{{ TemperatureZitrap.TmpTemperature01 }}
									<s-text
										v-model="TemperatureZitrap.TmpTemperature01"
										style="background: white"
									>
									</s-text></h1></b
						></v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-col>
			<!-- <v-col cols="4" lg="4">
				<v-card class="mx-auto" outlined>
					<v-list-item three-line>
						<v-list-item-content style="">
							<v-list-item-title class="text-h5 mb-1">
								<b>TEMPERATURA 2 (°C)</b>
							</v-list-item-title>
						</v-list-item-content>

						<v-list-item-avatar
							tile
							size="100"
							color="black"
							style="box-shadow: 1px 0px 4px 5px"
							><b style="color: red; font-size: 26px"
								><h1>
									{{ TemperatureZitrap.TmpTemperature02 }}
									<s-text
										v-model="TemperatureZitrap.TmpTemperature02"
										style="background: white"
									>
									</s-text></h1></b
						></v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-col>

			<v-col cols="4" lg="4">
				<v-card class="mx-auto" outlined>
					<v-list-item three-line>
						<v-list-item-content>
							<v-list-item-title class="text-h5 mb-1">
								<b>TEMPERATURA 3 (°C)</b>
							</v-list-item-title>
						</v-list-item-content>

						<v-list-item-avatar
							tile
							size="100"
							color="black"
							style="box-shadow: 1px 0px 4px 5px"
							><b style="color: red; font-size: 26px"
								><h1>
									{{ TemperatureZitrap.TmpTemperature03 }}
									<s-text
										v-model="TemperatureZitrap.TmpTemperature03"
										style="background: white"
									>
									</s-text></h1></b
						></v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-col> -->
		</v-row>
		<v-row style="margin: auto" align="center" justify="center">
			<v-col cols="12" lg="3" md="3">
				<v-btn width="100%" small color="info" @click="saveTmpEnvir()"> Guardar Temp. Amb. </v-btn>
			</v-col>
			<v-col cols="12" lg="3" md="3">
				<v-btn width="100%" small color="success" @click="showModalTemperatures()">
					<i class="fas fa-temperature-low"></i>
				</v-btn>
			</v-col>
		</v-row>
		<!-- </v-container> -->

		<!-- <v-container> -->
		<v-row>
			<div class="col-md-12 col-sm-12">
				<v-toolbar dense>
					<v-toolbar-title>En Cámara</v-toolbar-title>
				</v-toolbar>
				<v-card dense>
					<!-- <v-row style="margin: auto">
							<v-col
								cols="6"
								lg="2"
								md="4"
								sm="4"
								v-for="item in palletsInside"
								:key="item.CocID"
							>
								<v-card
									height="130"
									width="150"
									max-width="150"
									style="margin: auto"
								>
									<v-card-text class="justify-center">
										<b>{{ "P - " + item.PltID }}</b>
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											rounded
											color="primary"
											small
											@click="pickUpPallet(item)"
										>
											<v-icon>
												fas fa-truck-pickup
											</v-icon>
										</v-btn>
									</v-card-actions>
									<v-card-subtitle>
										<b>{{ item.SecCreate }}</b>
									</v-card-subtitle>
								</v-card>
							</v-col>
						</v-row> -->
					<v-row>
						<!-- :dropzonesTitle="'UBICACIÓN DE PALLET'" -->
						<v-col align="center" cols="12" lg="12" md="12">
							<drag-drop
								:dropzones="itemsPositionAll"
								:originalData="itemsPalletAll"
								:dropzonesTitle="'UBICACIÓN DE PALLET'"
								:inPlace="true"
								:enableSave="true"
								:enableTempPuple="true"
								@save="save"
								@tempPuple="tempPuple"
							>
								<template #dd-card="{ cardData }">
									<custom-card
										:data="cardData"
										:item="item"
										@weighed="weighed($event)"
										@pickUp="pickUp"
										@clickCheckBox="clickCheckBox"
									/>
								</template>
							</drag-drop>
						</v-col>
					</v-row>
				</v-card>
			</div>
		</v-row>
		<!-- </v-container> -->

		<v-dialog
			v-model="dialogTemperatureRecords"
			width="800"
		>
			<temperature-records
				@closeModalTemperatures="closeModalTemperatures()"
				:item="item"
			></temperature-records>
		</v-dialog>

		<v-dialog
			v-if="dialogTempPulpe"
			v-model="dialogTempPulpe"
			transition="dialog-bottom-transition"
			persistent
			width="800"
		>
			<v-card>
				<v-col>
					<v-row>
						<s-toolbar
							save
							@save="saveTmpPulple()"
							:color="'#BAB6B5'"
							dark
							label=""
							close
							@close="dialogTempPulpe = false"
						/>
					</v-row>

					<v-row style="margin-left: 5px" justify="center">
						<v-col
							lg="12"
							class="pt-0"
							cols="12"
							v-for="item in objCheckBox"
						>
							<v-toolbar dense>
								<v-toolbar-title
									><h4 style="-webkit-text-stroke: medium">
										Pallet - {{ item.PltID }}
									</h4></v-toolbar-title
								>
							</v-toolbar>
							<v-card dense>
								<v-row justify="center" style="margin: auto">
									<v-col cols="6" md="3" lg="3">
										<s-text
											label="Temp. Pulpa 01 (°C)"
											v-model="item.CodTempPulpe01"
											decimal
										></s-text>
									</v-col>

									<!-- <v-col cols="6" md="3" lg="3">
										<s-text
											label="Temp. Pulpa 02 (°C)"
											decimal
											v-model="item.CodTempPulpe02"
										></s-text>
									</v-col>

									<v-col cols="6" md="3" lg="3">
										<s-text
											label="Temp. Pulpa 03 (°C)"
											decimal
											v-model="item.CodTempPulpe03"
										></s-text>
									</v-col>
									<v-col cols="6" md="3" lg="3">
										<s-text
											label="Temp. Pulpa 04 (°C)"
											decimal
											v-model="item.CodTempPulpe04"
										></s-text>
									</v-col> -->
									<v-col cols="12" md="12" lg="12">
										<s-textarea
											label="Observaciones"
											v-model="item.CodObservation"
										></s-textarea>
									</v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-dialog>

		<v-dialog
			v-if="dialogWeighed"
			v-model="dialogWeighed"
			transition="dialog-bottom-transition"
			persistent
			width="400"
		>
			<v-card>
				<v-col>
					<v-row>
						<s-toolbar
							save
							@save="saveWeighed()"
							:color="'#BAB6B5'"
							dark
							label=""
							close
							@close="dialogWeighed = false"
						/>
					</v-row>

					<v-row justify="center" style="margin: auto">
						<v-col cols="12" md="12" lg="12">
							<s-text
								label="Peso Bruto (KG)"
								decimal
								v-model="itemWeighed.CocGrossWeight"
							></s-text>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import _sPackingService from "../../../services/FreshProduction/PackingService";
	import _sPrfChamberService from "../../../services/FreshProduction/PrfChamberService";
	import _sPrfCoolingChamber from "../../../services/FreshProduction/PrfCoolingChamberService";
	import _sTemperaturePalletService from "../../../services/FreshProduction/TemperaturePalletSevice";

	import CustomCard from "./CustomCard.vue";
	import TemperatureRecords from "./TemperatureRecords.vue";

	export default {
		props: {
			item: null,
			itemsPallet: null,
			itemsPosition: null,
		},
		components: { CustomCard, TemperatureRecords },

		data() {
			return {
				dialogTemperatureRecords: false,
				palletsInside: [],
				configScann: {},
				PltID: 0,
				TemperatureZitrap: {TmpTemperature01: 0, TmpTemperature02: 0, TmpTemperature03: 0},
				itemsPalletAll: [],
				objCheckBox: [],
				dialogTempPulpe: false,
				dialogWeighed: false,
				itemWeighed: {},
			};
		},

		created() {
			this.initialize();
			this.itemsPalletAll = this.itemsPallet;
			this.itemsPositionAll = this.itemsPosition;
		},

		methods: {
			showModalTemperatures()
			{
				this.dialogTemperatureRecords = true;
			},

			closeModalTemperatures()
			{
				this.dialogTemperatureRecords = false;
			},

			save(received) {
				console.log("Received:", received);

				if (received.originalBucket.length > 0) {
					received.originalBucket.forEach((element) => {
						element.SecStatus = 104;
						element.UsrCreateID = this.$fun.getUserID();
						element.ChpID = null;

						console.log(element);

						_sPrfCoolingChamber
							.save(element, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert(
										"Cambios guardados correctamente",
										"success"
									);
								}
							}); 

						/* _sPackingService
							.save(element, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert(
										"Cambios guardados correctamente",
										"success"
									);
								}
							}); */
					});
				}

				if (received.dropzones.length > 0) {
					received.dropzones.forEach((element) => {
						element.children.forEach((children) => {
							children.ChpID = element.ChpID;
							children.SecStatus = 104;
							children.UsrCreateID = this.$fun.getUserID();

							console.log("children", children);
							_sPrfCoolingChamber
								.save(children, this.$fun.getUserID())
								.then((resp) => {
									this.$fun.alert(
										"Cambios guardados correctamente",
										"success"
									);
								});

							/* _sPackingService
								.save(children, this.$fun.getUserID())
								.then((resp) => {
									this.$fun.alert(
										"Cambios guardados correctamente",
										"success"
									);
								}); */
						});
					});
				}

				/* received.dropzones.forEach(element => {
							element.children.forEach(children => {
								children.ChpID = element.ChpID;
								children.SecStatus = 100
								children.UsrCreateID = this.$fun.getUserID();

								console.log("children",children);

								_sPackingService.save(children, this.$fun.getUserID()).then(resp => {
									this.$fun.alert("Cambios guardados correctamente", "success")

								})
							});
						}); */
			},

			//OBTENER LAS TEMEPRATURAS DE ESE TUNEL
			initialize() {
				/* _sPrfChamberService
					.listTemperatureZitrap(
						this.item.SitrapID,
						this.$fun.getUserID()
					)
					.then((resp) => {
						if (resp.status == 200) {
							this.TemperatureZitrap = resp.data[0];
							console.log(
								"TemperatureZitrap",
								this.TemperatureZitrap
							);
						}
					}); */
			},

			onValue(val) {
				this.PltID = val;
			},

			closeDetail() {
				this.$emit("closeDetail");
			},

			goToChamber() {
				let item = {};
				this.$fun
					.alert("¿Seguro de pasar a Cámara?", "question")
					.then((val) => {
						if (val.value) {
							let coc = {
								PltID: this.PltID,
								ChaID: this.item.ChaID,
								TypeChamberTunnel: this.item.TypeChamberTunnel,
								CocStatus: 1,
								SecStatus: 1,
								UsrCreateID: this.$fun.getUserID(),
							};

							_sPrfCoolingChamber
								.save(coc, this.$fun.getUserID())
								.then((resp) => {
									if (resp.status == 200) {


										/* item.PltID = this.PltID;
										item.TypeStatusCoolingChamber = 2;
										item.SecStatus = 1;
										_sPackingService
											.save(item, this.$fun.getUserID())
											.then((res) => {
												if (res.status == 200) {
													this.closeDetail();
												}
											}); */

										this.$fun.alert(
											"Guardado Correctamente",
											"success"
										);
										this.closeDetail();


									}
								});
						}
					});
			},

			pickUp(data) {

				console.log("recoger pallet",data);

						
						this.$fun.alert("¿Seguro de recoger?", "question").then((val) => {
							if (val.value) {

								data.SecStatus = 105
								data.CocStatus = 2;

								_sPrfCoolingChamber
									.save(data, this.$fun.getUserID())
									.then((resp) => {
										if (resp.status == 200) {
											this.$fun.alert(
												"Recogido Correctamente",
												"success"
											);
											this.closeDetail();
										}
									});
							}
						});
					},

			clickCheckBox(data, itemCheck, element) {
				if (itemCheck) {
					this.objCheckBox.push(data);
				} else {
					this.objCheckBox = this.objCheckBox.filter(
						(x) => x.PltID != data.PltID
					);
				}

				console.log("objCheckBox", this.objCheckBox);
			},

			tempPuple() {
				if (this.objCheckBox.length == 0) {
					this.$fun.alert(
						"Seleccione Pallet a Ingresar Temperaturas",
						"warning"
					);
					return;
				}

				this.dialogTempPulpe = true;
			},

			saveTmpPulple() {
				console.log("guardar", this.objCheckBox);

				if(this.item.CocDateInitialProcess == null && this.item.TypeChamberTunnel == 2){
					this.$fun.alert("Error, Para registrar temperaturas de Pulpa, Inicie Proceso", "warning")
					return;
				}

				this.$fun.alert("¿Seguro de Guardar?", "question").then((val) => {
					if (val.value) {
						this.objCheckBox.forEach((element) => {
							element.CodHour = this.$fun.getHour();
							element.CodDate = this.$fun.getDate();
							element.SecStatus = 1;
							element.UsrCreateID = this.$fun.getUserID();

							_sTemperaturePalletService
								.save(element, this.$fun.getUserID())
								.then((resp) => {
									if (resp.status == 200) {
										this.dialogTempPulpe = false;
										this.closeDetail();
									}
								});
						});
					}
				});
			},

			weighed(item) {
				this.itemWeighed = item;
				this.dialogWeighed = true;
			},

			saveWeighed() {
				if (this.itemWeighed.CocGrossWeight <= 0) {
					this.$fun.alert("ingrese Peso Bruto", "warning");
					return;
				}

				console.log("Guardar", this.itemWeighed);

				this.$fun.alert("¿Seguro de Guardar?", "question").then((val) => {
					if (val.value) {
						this.itemWeighed.SecStatus = 102;
						this.itemWeighed.UsrCreateID = this.$fun.getUserID();
						_sPrfCoolingChamber
							.save(this.itemWeighed, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.dialogWeighed = false;
									this.closeDetail();
								}
							});
					}
				});
			},

			saveTmpEnvir(){

				if(this.item.ChaCountCamera <= 0 ){
					this.$fun.alert("Asigne Pallet para registrar temperaturas", "warning")
					return;
				}

				if(this.item.CocDateInitialProcess == null && this.item.TypeChamberTunnel == 2){
					this.$fun.alert("Error, Para registrar temperaturas de Pulpa, Inicie Proceso", "warning")
					return;
				}

				this.CoeID = 0;

				this.item.CoeTempEnvir01 = this.TemperatureZitrap.TmpTemperature01
				this.item.CoeTempEnvir02 = this.TemperatureZitrap.TmpTemperature02
				this.item.CoeTempEnvir03 = this.TemperatureZitrap.TmpTemperature03
				this.item.CoeHour = this.$fun.getHour();
				this.item.CoeDate = this.$fun.getDate();
				this.item.SecStatus = 1;
				this.item.UsrCreateID = this.$fun.getUserID();

				console.log("Guardar", this.item);
				this.$fun.alert("¿Seguro de Guardar?", "question").then((val) => {
					if (val.value) {

						_sTemperaturePalletService
							.saveEnvironment(this.item, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert("Registrado correctamente", "success")
									this.closeDetail();
								}
							});
					}
				});
			}
		},

		watch: {
			PltID() {
				this.goToChamber();
			},
		},
	};
</script>
<style scoped>
	.buttons {
		margin-top: 35px;
	}
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}
</style>
