<template>
	<div>
		<v-container>

			<!-- <v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col
								align="center"
								cols="6"
								lg="3"
								md="3"
								v-for="item in ChamberZitrap"
								:key="item.ChaID">
								<v-card
									class="mx-auto"
									dense>
									<v-card-title class="justify-center">
										<b style="font-family: ;">{{item.ChaDescription}}</b>
									</v-card-title>
									<v-card-text class="text-center">
										
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											fab
											@click="openModal(item)"
											:color="'info'"
											width="80"
											height="80"
										>
											<b><h1 style="font-size: 50px;">{{0}}</h1></b>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
							
						</v-row>
					</v-card>
				</div>
			</v-row> -->
			<!-- <v-row>
				<v-col cols="12">
					<v-btn color="success" width="100%">
						Iniciar Proceso
					</v-btn>
				</v-col>
				
			</v-row> -->
			<v-row>
				<div class="col-md-12 col-sm-12">
					<s-select-definition
						label="Destino"
						:def="1181"
						   
					/>
				</div>
			</v-row>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						
						<v-row style="margin: auto;">

							<v-col
								align="center"
								cols="12"
								lg="3"
								md="6"
								v-for="item in chambers"
								:key="item.ChaID">

								
								<v-card
									class="mx-auto"
									dense
								>
									<v-btn
										x-small
										fab
										dark
										:color="'error'"
										style="margin-left: 0px; margin-top: -20px;position: absolute; z-index: 2;"
										@click="clickTransfer(item)"
										v-if="item.ChaCountCamera > 0"
									>
										<v-icon style="font-size: 16px !important; ">fas fa-exchange-alt</v-icon>
									</v-btn>
									
									<v-btn
										v-if="item.ChaCountCamera > 0 && item.TypeChamberTunnel == 2"
										x-small
										fab
										dark
										:color="'#a7a7a7'"
										style="margin-left: 195px; margin-top: -20px; z-index: 2;"
										@click="pickUp(item)"
									>
										<v-icon style="font-size: 16px !important">fas fa-arrow-circle-up</v-icon>
									</v-btn>
									<v-card-title class="justify-center">
										<h4>{{item.ChaDescription}}</h4>
										
									</v-card-title>

									<v-card-title class="justify-center" v-if="isAdmin">
										<!-- <h4>{{'P.N: ' + item.ChaWeigthNet + 'KG'}}</h4>
										<v-spacer></v-spacer> -->
										<v-btn
											fab
											small
											color="success"
											@click="openDialogAdmin(item)"
										>
											<i class="fas fa-info"></i>
										</v-btn>
									</v-card-title>

									<v-card-text class="text-center">
										<b>{{item.TypeFreshProductionDestinationText}}</b>
										<h4>{{item.CoeTempEnvironment}}</h4>
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											fab
											@click="openModal(item)"
											:color="item.ChaCountCamera > 0 ? 'error':'info'"
											width="80"
											height="80"
										>
											<b><h1 style="font-size: 50px;">{{item.ChaCountCamera}}</h1></b>
										</v-btn>
									</v-card-actions>
									<b>{{item.CocDateInitialProcess}}</b>     
									<v-btn v-if="item.ChaCountCamera > 0 && item.TypeChamberTunnel == 2" :disabled="item.CocDateInitialProcess == null ? false: true" small elevation="2" width="100%" @click="clickInitialProcess(item)">
										Iniciar Proceso
									</v-btn>
		
								</v-card>

								<!-- <v-checkbox style="position: absolute; margin-top: -30px; margin-left: 0px;" v-model="itemCheckBox">
								</v-checkbox> -->
								
					
							</v-col>

							
						</v-row>
					</v-card>
				</div>
			</v-row>
			
		</v-container>

		<v-dialog
			v-if="dialogChamberAdmin"
			v-model="dialogChamberAdmin"
		>
			<cooling-chamber-admi
				@closeDialogAdmin="closeDialogAdmin()"
				:chamberSelected="chamberSelected"
			></cooling-chamber-admi>
		</v-dialog>

		<v-dialog
			v-if="dialog"
			v-model="dialog"
			transition="dialog-bottom-transition"
			persistent>
			<cooling-chamber-detail 
				@closeDetail="closeDetail" 
				:item="item"
				:itemsPallet="itemsPallet"
				:itemsPosition="itemsPosition"
			></cooling-chamber-detail>
		</v-dialog>	

		<v-dialog
			v-if="dialogTransfer"
			v-model="dialogTransfer"
			transition="dialog-bottom-transition"
			persistent
			width="800"
		>
			<v-card>
				<v-col>
					<v-row>
						<s-toolbar
							save
							@save="saveTransfer()"
							:color="'#BAB6B5'"
							dark
							label="Transferencia"
							close
							@close="dialogTransfer  = false"
						/>
					</v-row>

					<!-- <v-row style="margin-left: 5px" justify="center"> -->
							<!-- <v-card dense> -->

								<v-row justify="center" style="margin:auto; margin-left: 5px">
									<v-col cols="12" md="12" lg="12">
										<s-select
											label="Tuneles"
											:items="chambers"
											item-value="ChaID"
											item-text="ChaDescription"
											v-model="itemTransfer.CocID"
										></s-select>
									</v-col>
									<!-- <v-col cols="12" md="12" lg="12" class="mt-0 pt-0 pb-0">
										<s-textarea
											label="Comentario"
											v-model="itemTransfer.ChaComment"
										>

										</s-textarea>
									</v-col> -->
								</v-row>
							<!-- </v-card> -->

						
					<!-- </v-row> -->
				</v-col>
			</v-card>
		</v-dialog>
		

	</div>
</template>

<script>
	import _sPrfChamberService from "../../../services/FreshProduction/PrfChamberService";
	import _sPrfCoolingChamber from "../../../services/FreshProduction/PrfCoolingChamberService";

	import CoolingChamberDetail from './CoolingChamberDetail.vue';
	import CoolingChamberAdmi from './CoolingChamberAdmi.vue'

	export default {
  		components: { CoolingChamberDetail, CoolingChamberAdmi },
		data () {
			return { 
				
				dialog: false,
				dialogTransfer:false,
				chambers: [],
				item: {},
				itemsPallet: [],
				itemsPosition: [],
				itemTransfer: {},
				isAdmin: false,
				dialogChamberAdmin: false,
				chamberSelected: {}
				/* itemCheckBox:false */
			}
		},

		created () {
			this.initialize();
			this.isAdmin = this.$fun.isAdmin();
		},

		methods: {

			initialize() {
				//Listar tuneles
				_sPrfChamberService.list(this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){
						
						resp.data.forEach(element => {
							if(element.CocDateInitialProcess !== null){
								element.CocDateInitialProcess = this.$moment(element.CocDateInitialProcess).format(this.$const.FormatDateTimeDB);
							}
							
						});

						this.chambers = resp.data
						console.log("camaras", this.chambers);
					}
				})
			},

			openDialogAdmin(item)
			{
				this.chamberSelected = item;
				this.dialogChamberAdmin = true;
			},

			closeDialogAdmin()
			{
				this.dialogChamberAdmin = false;
			},

			showDetail(item) {

				this.item = item;

				/* if(this.item.SitrapID > 0){ */
					_sPrfCoolingChamber
						.list(
							{ ChaID: this.item.ChaID, TypeDestiny: "UUU" },
							this.$fun.getUserID()
						)
						.then((resp) => {
							if (resp.status == 200) {
								resp.data.forEach((element) => {
									element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
									//element.CodHour = this.$moment(element.CodHour).format(this.$const.FormatTimeDB);
									element.done = false
								});

								this.itemsPallet = resp.data;

								_sPrfChamberService
								.listChamberPosition(
									{ChaID: this.item.ChaID},
									this.$fun.getUserID()
								)
								.then((resp) => {
									if (resp.status == 200) {
										resp.data.forEach(element => {
											element.name = element.ChpDescription
											

											element.children.forEach(children => {
												children.SecCreate = this.$moment(children.SecCreate).format(this.$const.FormatDateTimeDB);
												//children.CodHour = this.$moment(children.CodHour).format(this.$const.FormatTimeDB);
											});
										});
										this.itemsPosition = resp.data;
										this.dialog = true;
										
									}
								});
								
							}
						});

				/* }else{
					this.$fun.alert("Error, no se han vinculado tunel con Sitrap", "warning")
				} */
			},

			openModal(item){
				this.showDetail(item);
				
			},

			closeDetail()
			{	
				this.itemsPallet =  []
				this.itemsPosition =  []
				this.dialog = false;
				this.initialize();
			},

			pickUp(item){

				this.$fun.alert("¿Seguro de recoger?", "question").then((val) => {
					if (val.value) {
						item.CocID = 1
						item.CocStatus = 2;
						item.SecStatus = 100;
						item.UsrUpdateID = this.$fun.getUserID();
						_sPrfCoolingChamber
							.save(item, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert(
										"Recogido Correctamente",
										"success"
									);
									this.initialize();
								}
							});
					}
				});
			},

			clickTransfer(item){
				this.itemTransfer = item
				this.dialogTransfer = true
			},

			saveTransfer(){
				if(this.itemTransfer.CocID == undefined) {
					this.$fun.alert("Seleccione tunel", "warning")
					return
				}
				this.itemTransfer.SecStatus = 101;
				this.itemTransfer.UsrCreateID = this.$fun.getUserID();

				this.$fun.alert("¿Seguro de transferir?", "question").then((val) => {
					if (val.value) {
						_sPrfCoolingChamber
							.save(this.itemTransfer, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert(
										"Transferido Correctamente",
										"success"
									);
									this.initialize();
									this.dialogTransfer = false
								}
							});
					}
				});
			},

			clickInitialProcess(item){

				if(item.ChaCountCamera <= 0){
					this.$fun.alert("Error, No se Puede iniciar Proceso", "warning")
					return;
				}

				this.$fun.alert("¿Seguro de Iniciar Proceso ?", "question").then((val) => {
					if (val.value) {
						item.CocID = 1
						item.SecStatus = 103;
						_sPrfCoolingChamber
							.save(item, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									this.$fun.alert(
										"Proceso Iniciado Correctamente",
										"success"
									);
									this.initialize();
								}
							});
					}
				});
			}



			
		},

/* 
		initialize(){
				_sPrfChamberService.list(this.$fun.getUserID())
				.then((resp) => {
					if(resp.status == 200)
					{
						this.chambers = resp.data;
					}
				});
			} */
	}
</script>



