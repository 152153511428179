import Service from "../Service";

const resource = "TemperaturePallet/"

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    saveEnvironment(obj, requestID) {
        return Service.post(resource + "saveEnvironment", obj, {
            params: { requestID: requestID },
        });
    },

    listtemperatureenvrecords(obj, requestID) {
        return Service.post(resource + "listtemperatureenvrecords", obj, {
            params: { requestID: requestID },
        });
    },


    listtemperaturepulprecords(obj, requestID) {
        return Service.post(resource + "listtemperaturepulprecords", obj, {
            params: { requestID: requestID },
        });
    },

};