<template>
	<div class="cc-card">

		<!-- <v-btn x-small fab color="success" v-if="data.TypeBalance == 0" style="z-index: 2;transform: rotateZ(316deg);margin-top: 20px;margin-left: 140px;position: absolute;">SA{{data.TypeBalance}}</v-btn> -->
		<v-btn
			x-small
			elevation="0"
			fab
			dark
			color="error"
			style="margin-left: -15px; margin-top: -20px"
			@click="weighed()"
		>
			<v-icon style="font-size: 16px !important"
				>fas fa-balance-scale</v-icon
			>
		</v-btn>

		<v-btn
			v-if="item.TypeChamberTunnel == 1"
			x-small
			fab
			dark
			:color="'success'"
			style="
				margin-left: 130px;
				margin-top: -18px;
				position: absolute; 
			"
			@click="pickUp(data)"
		>
			<v-icon style="font-size: 16px !important"
				>fas fa-arrow-circle-up</v-icon
			>
		</v-btn>

		<h5>{{ data.CodHour }}</h5>
		<h3>
			P - {{ data.PltID + "-" + data.PltCorrelative }} ||
			{{ data.CocGrossWeight == null ? 0 : data.CocGrossWeight }} KG
		</h3>
		<p style="-webkit-text-stroke: thick; font-size: 12px">
			OV: {{ data.OrdID }}
		</p>

		({{ data.CodTempPulpe == null ? "Sin Temp." : data.CodTempPulpe }}) °C
		<br />
		{{ data.SecCreate }}
		<v-checkbox
			style="position: absolute; margin-top: -10px; margin-left: -20px"
			@click="clickCheckBox(data, itemCheck, $event)"
			v-model="itemCheck"
		>
		</v-checkbox>
	</div>
</template>

<script>
	export default {
		name: "CustomCard",
		props: {data: null, item: null},
		data() {
			return {
				itemCheck: false,
			};
		},
		methods: {
			weighed() {
				this.$emit("weighed", this.data);
			},

			pickUp(data){
				this.$emit('pickUp', data)
			},

			clickCheckBox(data, itemCheck, element) {
				this.$emit("clickCheckBox", data, itemCheck, element);
			},
		},
	};
</script>
<style>
	.cc-card {
		padding: 10px;
		text-align: left;
		border: 1px solid #dbdbdb;
		border-radius: 5px;
		margin: 5px;
		background-color: white;
		min-width: 250px;
		font-size: 1em;
	}
/*min-width cambio samir de 200 a 250 */
	.cc-card h3 {
		margin: 0px;
	}
</style>